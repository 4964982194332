var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// External Imports
import React, { Fragment, useId } from "react";
// Internal Imports
import { combineClasses, range } from "components/Utility/utils";
function ProgressBar(_a) {
    var { labelHidden = true, max = 2, value = 1 } = _a, props = __rest(_a, ["labelHidden", "max", "value"]);
    const ariaLabelledBy = useId();
    return (React.createElement(Fragment, null,
        React.createElement("label", { id: ariaLabelledBy, className: combineClasses(labelHidden && "sr-only") }, props.label),
        React.createElement("div", { className: combineClasses("flex-container", "progress-bar", props.addClass), role: "progressbar", "aria-labelledby": ariaLabelledBy, "aria-valuemin": 1, "aria-valuemax": max, "aria-valuenow": value }, range(1, max).map((num, index) => {
            return (React.createElement("div", { key: index, className: combineClasses(`progress-bar-${max}`, num <= value && "active") }));
        }))));
}
export { ProgressBar };
