// External Imports
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
// Internal Imports
import { Button } from "components/components";
import { notFoundPageImg } from "assets/images/images";
function NotFoundPage() {
    const navigate = useNavigate();
    return (React.createElement(Fragment, null,
        React.createElement("main", null,
            React.createElement("div", { className: "not-found-container flex-container justify-between align-center gap-3" },
                React.createElement("div", { className: "not-found-box" },
                    React.createElement("h1", { className: "not-found-title mt-0 mb-3" }, "Page not found"),
                    React.createElement("p", { className: "not-found-paragraph mb-3" }, "We can't seem to find the page you're looking for. Try going back to the previous page."),
                    React.createElement(Button, { length: "long", size: "lg", onClick: () => navigate(-1) }, "Go Back")),
                React.createElement("div", null,
                    React.createElement("img", { src: notFoundPageImg, alt: "" }))))));
}
export { NotFoundPage };
