// @ts-nocheck
// External Imports
import React, { Fragment, useState } from "react";
// Internal Imports
import { Checkbox, Chip, TextField, Notification, ChevronScroll, } from "components/components";
import { IconEyeClose, IconEyeOpen, IconSearch, IconDropdownDown, } from "assets/images/images";
import { combineClasses } from "components/Utility/utils";
function Demo() {
    function textFieldOnChange(e) {
        console.log(e.target.value);
    }
    function checkboxOnChange(e) {
        console.log(e.target.checked);
    }
    return (React.createElement(Fragment, null,
        React.createElement(Notification, { closable: true, fade: true },
            "This is a limited view. Please",
            " ",
            React.createElement("a", { href: "https://www.hackforla.org/getting-started", rel: "noopener noreferrer" }, "attend onboarding"),
            " ",
            "with Hack for LA or log in to see full opportunities."),
        React.createElement("br", null),
        React.createElement(Notification, { closable: true },
            "This is a limited view. Please",
            " ",
            React.createElement("a", { href: "https://www.hackforla.org/getting-started", rel: "noopener noreferrer" }, "attend onboarding"),
            " ",
            "with Hack for LA or log in to see full opportunities."),
        React.createElement("br", null),
        React.createElement(Notification, { autoHidden: true },
            "This is a limited view. Please",
            " ",
            React.createElement("a", { href: "https://www.hackforla.org/getting-started", rel: "noopener noreferrer" }, "attend onboarding"),
            " ",
            "with Hack for LA or log in to see full opportunities."),
        React.createElement("br", null),
        React.createElement("div", { className: "m-5" }),
        React.createElement("div", { className: "m-5" },
            React.createElement("h1", null, "Hello World! Feel free to use this page as a playground to test code!"),
            React.createElement("h2", null, "Chevron Scroll"),
            React.createElement(ChevronScroll, null, [
                "Roles",
                "Availability",
                "Experience Level",
                "Program Areas",
                "Languages/Technologies",
                "Location",
            ].map((category, idx) => (React.createElement("button", { key: idx, value: category, className: "demo-chevron-scroll-btn" },
                category,
                " ",
                React.createElement(IconDropdownDown, { className: "ml-2 mr-1" }))))),
            React.createElement("div", { style: { width: "720px" } },
                React.createElement("h2", null, "Single Chips"),
                React.createElement("div", { style: { width: "500px" } }, ["Designer", "Engineer", "Researcher", "PM"].map((role, index) => {
                    return (React.createElement(Chip, { key: index, addClass: combineClasses("mr-3", index >= 2 && "fill"), onChange: (active, value) => {
                            console.log(`${value} was ${active ? "selected" : "deselected"}`);
                        }, value: role }));
                })),
                React.createElement("h2", null, "Multi Chips"),
                React.createElement("div", null, ["Designer", "Engineer", "Researcher", "PM"].map((role, index) => {
                    return (React.createElement(Chip, { key: index, variant: "multi", addClass: "mr-3", onChange: (active, value) => {
                            console.log(`${value} was ${active ? "selected" : "deselected"}`);
                        }, value: role }));
                })),
                React.createElement("h2", null, "Checkboxes"),
                React.createElement(Checkbox, { label: "Enabled selected", defaultChecked: true }),
                React.createElement(Checkbox, { label: "Enabled unselected" }),
                React.createElement(Checkbox, { label: "Disabled selected", defaultChecked: true, disabled: true }),
                React.createElement(Checkbox, { label: "Disabled unselected", disabled: true }),
                React.createElement(Checkbox, { label: "Enabled selected", defaultChecked: true, labelHidden: true, onChange: checkboxOnChange }),
                React.createElement("h2", null, "Textfields"),
                React.createElement(TextField, { type: "email", label: "Login", placeholder: "email here", onChange: textFieldOnChange, addClass: "m-1" }),
                React.createElement(TextField, { type: "password", label: "Password", addClass: "m-1", icon: PasswordButton, iconPosition: "right" }),
                React.createElement(TextField, { type: "date", label: "What is today's date?", addClass: "m-1" }),
                React.createElement(TextField, { type: "number", label: "How old are you?", addClass: "m-1" }),
                React.createElement(TextField, { type: "search", label: "Search", addClass: "m-1", icon: IconSearch }),
                React.createElement(TextField, { type: "tel", label: "What is your number?", addClass: "m-1" }),
                React.createElement(TextField, { type: "time", label: "What is the current time?", addClass: "m-1", addInputClass: "textfield-fit" }),
                React.createElement(TextField, { type: "url", label: "Personal website", addClass: "m-1" })))));
}
function PasswordButton() {
    const [isOpen, setIsOpen] = useState(true);
    function handleClick() {
        setIsOpen(!isOpen);
    }
    return (React.createElement("button", { onClick: handleClick, style: {
            border: "none",
            backgroundColor: "#fff",
        } }, isOpen ? React.createElement(IconEyeOpen, null) : React.createElement(IconEyeClose, null)));
}
export { Demo };
