// External imports
import React, { Fragment } from "react";
// Internal imports
import { Button } from "../Buttons/Button";
import { logoHorizontalOnDark, logoStackedOnDark } from "assets/images/images";
const menuItems = [
    { name: "Credits", link: "/credits" },
    { name: "Sitemap", link: "/" },
    { name: "Join Us", link: "/" },
];
function FooterNav() {
    const Logo = () => {
        return (React.createElement("a", { className: "footer-icons-on-dark", href: "/", rel: "noopener noreferrer" },
            React.createElement("img", { className: "logo-desktop-footer", src: logoHorizontalOnDark, alt: "Civic Tech Jobs - Home" }),
            React.createElement("img", { className: "logo-mobile-footer", src: logoStackedOnDark, alt: "Civic Tech Jobs - Home" })));
    };
    return (React.createElement("footer", { className: "footer-nav flex-container" },
        React.createElement(Logo, null),
        React.createElement("nav", { className: "footer-menu flex-container", "aria-label": "footer-navigation" }, menuItems.map((item, index) => {
            return (React.createElement(Fragment, { key: index },
                React.createElement("div", { className: "footer-menu-vertical-line" }),
                React.createElement("a", { className: "footer-links", href: item.link, rel: "noopener noreferrer" }, item.name)));
        })),
        React.createElement("div", { className: "footer-donate-button flex-container" },
            React.createElement(Button, { color: "primary-dark", href: "https://www.hackforla.org/donate/", size: "sm" }, "Donate"))));
}
export { FooterNav };
