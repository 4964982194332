var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// Eternal Imports
import React, { Fragment, useEffect, useId, useState } from "react";
import { usePopper } from "react-popper";
// Internal Imports
import { ProtoInput } from "./ProtoInput";
import { IconDropdownDown, IconDropdownUp } from "assets/images/images";
import { combineClasses, onKey } from "components/Utility/utils";
function Dropdown(_a) {
    var { labelHidden = false } = _a, props = __rest(_a, ["labelHidden"]);
    const [open, setOpen] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes, state } = usePopper(referenceElement, popperElement, {
        placement: "bottom-start",
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0, 0],
                },
            },
        ],
    });
    const dropdownId = useId();
    const dropdownBoxId = useId();
    useEffect(() => {
        const popperElement = state === null || state === void 0 ? void 0 : state.elements.popper;
        const dropdownElement = state === null || state === void 0 ? void 0 : state.elements.reference;
        // Close popup if click is outside of dropdown
        function handleClick(e) {
            if (e.target instanceof HTMLElement) {
                if (popperElement === null || popperElement === void 0 ? void 0 : popperElement.contains(e.target)) {
                    setOpen(false);
                }
                else if (dropdownElement instanceof HTMLElement &&
                    (dropdownElement === null || dropdownElement === void 0 ? void 0 : dropdownElement.contains(e.target))) {
                    return;
                }
                else {
                    setOpen(false);
                }
            }
        }
        document.body.addEventListener("click", handleClick);
        return () => {
            document.body.removeEventListener("click", handleClick);
        };
    }, [state]);
    return (React.createElement(Fragment, null,
        React.createElement(ProtoInput, { addClass: props.addClass, icon: open ? IconDropdownUp : IconDropdownDown, iconPosition: "right", id: dropdownId, label: props.label, labelHidden: labelHidden, passRef: setReferenceElement },
            React.createElement("div", { "aria-controls": dropdownBoxId, "aria-expanded": open, "aria-label": props.ariaLabel, "aria-owns": dropdownBoxId, id: dropdownId, className: "dropdown", onClick: () => setOpen(!open), onKeyDown: (e) => onKey(() => setOpen(!open), "Enter")(e), onMouseDown: (e) => e.preventDefault(), role: "combobox", tabIndex: 0 }, props.value)),
        React.createElement("ul", Object.assign({ className: combineClasses("dropdown-box p-0 m-0", open || "hidden"), id: dropdownBoxId, ref: setPopperElement, role: "listbox", style: styles.popper }, attributes.popper), props.children)));
}
function DropdownOption(_a) {
    var props = __rest(_a, []);
    return (React.createElement("li", { "aria-selected": props.selected, className: "dropdown-row px-2", onClick: () => props.onClick(props.value), onKeyDown: (e) => onKey(props.onClick, "Enter")(e), role: "option" },
        React.createElement("div", null, props.children)));
}
export { Dropdown, DropdownOption };
