import React from "react";
const TopSvg = (props) => (React.createElement("svg", Object.assign({ viewBox: "0 0 1440 520", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { className: "w-full h-1/3" }),
    React.createElement("g", { "clip-path": "url(#clip0_6412_58802)" },
        React.createElement("path", { opacity: "0.5", d: "M644 374.717C999.77 437.091 1245.5 429.53 1441 355L1441 785L-3.75918e-05 785L-3.45772e-06 394.552C-3.45772e-06 394.552 426.5 336.584 644 374.717Z", fill: "url(#paint0_linear_6412_58802)" }),
        React.createElement("path", { d: "M915.783 410.283C449.407 347.909 127.28 355.47 -129 430V0H1760V390.448C1760 390.448 1200.9 448.416 915.783 410.283Z", fill: "#FFE0B9" }),
        React.createElement("path", { opacity: "0.5", d: "M797 410.283C441.23 347.909 195.5 355.47 0 430V0H1441V390.448C1441 390.448 1014.5 448.416 797 410.283Z", fill: "#FFEFDB" })),
    React.createElement("defs", null,
        React.createElement("linearGradient", { id: "paint0_linear_6412_58802", x1: "720.5", y1: "355", x2: "720.5", y2: "785", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { "stop-color": "#FFEFDB" }),
            React.createElement("stop", { offset: "0.339691", "stop-color": "#FFEFDB", "stop-opacity": "0" })),
        React.createElement("clipPath", { id: "clip0_6412_58802" },
            React.createElement("rect", { width: "1440", height: "520", fill: "white" })))));
export default TopSvg;
