var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// Eternal Imports
import React, { useId } from "react";
// Internal Imports
import { combineClasses } from "../Utility/utils";
import { ProtoInput } from "./ProtoInput";
function TextField(_a) {
    var { iconPosition = "left", labelHidden = false, type = "text" } = _a, props = __rest(_a, ["iconPosition", "labelHidden", "type"]);
    const textFieldId = useId();
    return (React.createElement(ProtoInput, { addClass: props.addClass, icon: props.icon, id: textFieldId, iconPosition: iconPosition, label: props.label, labelHidden: labelHidden },
        React.createElement("input", { id: textFieldId, type: type, className: combineClasses(props.icon
                ? `textfield-${iconPosition == "left" ? "right" : "left"}`
                : "textfield", props.addInputClass), onChange: (e) => {
                if (props.onChange)
                    props.onChange(e);
            }, placeholder: props.placeholder })));
}
export { TextField };
