// External Imports
import React from "react";
import { createBrowserRouter } from "react-router-dom";
// Internal Imports
import { CreditsPage } from "pages/CreditsPage/CreditsPage";
import { Demo } from "pages/Demo/Demo";
import DemoTailwind from "pages/Demo/DemoTailwind";
import { NotFoundPage } from "pages/NotFoundPage/NotFoundPage";
import { LandingPage } from "pages/LandingPage/LandingPage";
import { QualifierPage, QualifierContent, loader as qualifierLoader, } from "pages/QualifierPage/QualifierPage";
import AuthenticationPage from "pages/Authentication/page";
import HomeLayout from "layouts/HomeLayout";
import DefaultNavLayout from "layouts/DefaultNavLayout";
import PrivacyPolicyPage from "pages/PrivacyPolicyPage/PrivacyPolicyPage";
const router = createBrowserRouter([
    {
        path: "/",
        element: React.createElement(HomeLayout, null),
        children: [
            {
                path: "/",
                element: React.createElement(DefaultNavLayout, null),
                children: [
                    {
                        index: true,
                        element: React.createElement(LandingPage, null),
                    },
                    {
                        path: "qualifier",
                        element: React.createElement(QualifierPage, null),
                        children: [
                            {
                                path: ":page",
                                element: React.createElement(QualifierContent, null),
                                loader: qualifierLoader,
                            },
                        ],
                    },
                    {
                        path: "credits",
                        element: React.createElement(CreditsPage, null),
                    },
                    {
                        path: "demo",
                        element: React.createElement(Demo, null),
                    },
                    {
                        path: "demo-tailwind",
                        element: React.createElement(DemoTailwind, null),
                    },
                    {
                        path: "*",
                        element: React.createElement(NotFoundPage, null),
                    },
                    {
                        path: "privacypolicy",
                        element: React.createElement(PrivacyPolicyPage, null),
                    },
                ],
            },
        ],
    },
    {
        path: "login",
        element: React.createElement(AuthenticationPage, null),
    },
    {
        path: "signup",
        element: React.createElement(AuthenticationPage, null),
    },
]);
export default router;
