import React from "react";
import { Link } from "react-router-dom";
import { TextField } from "tw-components";
import { useForm } from "react-hook-form";
/** Signup Form Component
 * @dev used on the Authentication page
 * @dev noValidate on form to disable browser vaildation so we can use react-hook-form validations instead
 */
export default function SignupForm() {
    const { register, handleSubmit, formState: { errors }, } = useForm();
    const onSubmit = (data) => {
        console.log("Sending form data to server...", data);
    };
    return (React.createElement("div", null,
        React.createElement("h3", { className: "mb-10 text-4xl font-bold" }, "Sign up"),
        React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", { className: "grid grid-cols-1 md:grid-cols-2 md:gap-4" },
                React.createElement(TextField, { label: "First name", id: "firstName", type: "text", register: register, errors: errors.firstName, validations: { required: "Please enter first name" } }),
                React.createElement(TextField, { label: "Last Name", id: "lastName", type: "text", register: register, errors: errors.lastName, validations: { required: "Please enter last name" } })),
            React.createElement(TextField, { label: "Email", id: "email", type: "email", register: register, errors: errors.email, validations: {
                    required: "Please enter your email address",
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Please enter a valid email address",
                    },
                } }),
            React.createElement(TextField, { label: "Password", id: "password", type: "password", register: register, validations: {
                    required: "Please enter your password",
                    pattern: {
                        value: /^(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/,
                        message: "Must be 8 or more characters and contain at least 1 number and 1 special character",
                    },
                }, errors: errors.password }),
            React.createElement("button", { className: "font-bold w-full text-white py-[12px] rounded-3xl bg-blue-dark hover:bg-blue-dark-hover hover:shadow-lg focus:bg-blue-dark-focused" }, "Sign Up")),
        React.createElement("div", { className: "text-center mt-4" },
            React.createElement("p", null,
                "Already on Civic Tech Jobs?",
                " ",
                React.createElement(Link, { to: "/login", className: "text-blue-dark font-bold underline" }, "Log In")))));
}
