import React from "react";
const Card = ({ name, usedIn, provider, imgSrc, imgContainerStyleClasses, imgStyleClasses, learnMoreLink, }) => {
    return (React.createElement("div", { className: "auto-cols-max rounded-xl shadow-md p-3 md:p-6 flex flex-row sm:flex-col items-center justify-center md:justify-evenly gap-4" },
        React.createElement("div", { className: imgContainerStyleClasses },
            React.createElement("img", { className: imgStyleClasses, src: imgSrc, alt: name })),
        React.createElement("div", null,
            React.createElement("table", { className: "table-fixed text-left text-xs sm:text-sm lg:text-base xl:text-lg" },
                React.createElement("tbody", null,
                    React.createElement("tr", { className: "pb-1" },
                        React.createElement("th", { className: "pr-2" }, "Name:"),
                        React.createElement("td", null, name)),
                    React.createElement("tr", { className: "pb-1" },
                        React.createElement("th", { className: "pr-2" }, "Used In:"),
                        React.createElement("td", null, usedIn)),
                    React.createElement("tr", { className: "pb-1" },
                        React.createElement("th", { className: "pr-2" }, "Provider:"),
                        React.createElement("td", null, provider)))),
            React.createElement("div", { className: "mt-3 md:mt-6" },
                React.createElement("a", { href: learnMoreLink, className: "text-blue-link underline font-semibold text-sm sm:text-base md:text-lg lx:text-xl" }, "Learn more")))));
};
export default Card;
