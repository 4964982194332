import React from "react";
import { Outlet } from "react-router-dom";
import { FooterNav } from "components/components";
import { HeaderNav } from "tw-components";
const DefaultNavLayout = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(HeaderNav, null),
        React.createElement(Outlet, null),
        React.createElement(FooterNav, null)));
};
export default DefaultNavLayout;
