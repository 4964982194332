var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// Eternal Imports
import React, { useId, useLayoutEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
// Internal Imports
import { combineClasses } from "../Utility/utils";
import { IconCheckboxY, IconCheckboxN } from "assets/images/images";
function Checkbox(_a) {
    var { defaultChecked = false, disabled = false, labelHidden = false } = _a, props = __rest(_a, ["defaultChecked", "disabled", "labelHidden"]);
    /* Notes on when to use color
    disabledColor: use this for stroke and fill for Y and stroke only for N
    enabledYColor: use this for stroke and fill for Y
    enabledNColor: use this for stroke only N
    */
    const disabledColor = "#C1C1C1";
    const enabledYColor = "#3450A1";
    const enabledNColor = "#585858";
    const checkboxId = useId();
    const checkboxRef = useRef(null);
    const nodeRef = useRef(null);
    const [isChecked, setIsChecked] = useState(defaultChecked);
    useLayoutEffect(() => {
        if (checkboxRef.current) {
            checkboxRef.current.checked = isChecked;
        }
    }, [isChecked]);
    function handleChange(e) {
        if (!disabled) {
            setIsChecked(e.target.checked);
        }
        if (props.onChange) {
            props.onChange(e);
        }
    }
    return (React.createElement("div", { className: combineClasses("checkbox", props.addClass) },
        React.createElement("input", { className: combineClasses("checkbox-input", "sr-only", props.addClass), id: checkboxId, type: "checkbox", defaultChecked: defaultChecked, disabled: disabled, ref: checkboxRef, onChange: handleChange }),
        React.createElement("label", { className: combineClasses("checkbox-label", disabled && "checkbox-disabled"), htmlFor: checkboxId },
            React.createElement(CSSTransition, { in: isChecked, classNames: "checkbox-icon", timeout: 300, nodeRef: nodeRef },
                React.createElement("span", { className: "checkbox-icon", ref: nodeRef }, isChecked ? (React.createElement(IconCheckboxY, { height: "24", width: "24", fill: disabled ? disabledColor : enabledYColor, stroke: disabled ? disabledColor : enabledYColor, viewBox: "0 0 24 24", "aria-hidden": "true" })) : (React.createElement(IconCheckboxN, { height: "24", width: "24", fill: "#fff", stroke: disabled ? disabledColor : enabledNColor, viewBox: "0 0 24 24", "aria-hidden": "true" })))),
            React.createElement("span", { className: combineClasses(labelHidden && "sr-only") }, props.label))));
}
export { Checkbox };
