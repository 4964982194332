// External Imports
import React, { useState, useEffect } from "react";
// Internal Imports
import { Button, CircleCard, Dialog } from "components/components";
import { CopCard, InnerCopCard, InnerCopNavCard } from "./LandingPageCopCards";
import { fetchAllCopData, fetchCopDataById, } from "../../api_data/copData";
function LandingPageCop() {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [copData, setCopData] = useState([]);
    const [currentDatum, setCurrentDatum] = useState({});
    useEffect(() => {
        setCopData(fetchAllCopData());
    }, []);
    function handleCopData(id) {
        const copDatum = fetchCopDataById(id);
        if (copDatum) {
            setCurrentDatum(copDatum);
        }
    }
    return (React.createElement("div", { className: "flex-container align-center justify-center py-5" },
        React.createElement("h2", { className: "col-12 text-center text-4xl font-bold leading-normal my-8" }, "Communities of Practice (COP)"),
        React.createElement("div", { className: "row paragraph-1 text-center mb-5 landing-cop-description" }, "A Community of Practice (CoP) is a group of volunteers who share a common interest in a topic and meet regularly to fulfill both individual and group goals. We use CoPs to share effective practices and relevant domain knowledge to help our members grow."),
        React.createElement("div", { className: "row m-10 landing-cop-circle-container" }, copData.map((cop) => {
            return (React.createElement(CircleCard, { key: cop.id, size: "lg", addClass: "m-8", onClick: () => {
                    handleCopData(cop.id);
                    setIsDialogOpen(true);
                }, role: "button" },
                React.createElement("div", { className: "flex-column" },
                    React.createElement("div", { className: "pb-6 row justify-center" },
                        React.createElement(cop.icon, { strokeWidth: "0.2", height: "65", "aria-hidden": "true" })),
                    React.createElement("div", { className: "title-4 landing-cop-circle-title text-center" }, cop.title))));
        })),
        React.createElement(Dialog, { open: isDialogOpen, onClose: () => {
                setIsDialogOpen(false);
            }, addClass: "flex-container justify-center align-center", ariaLabel: "Communities of Practice (COP)" },
            React.createElement(CopCard, { hidden: false, size: "lg", onClick: () => setIsDialogOpen(false) },
                React.createElement("div", { className: "flex-container" },
                    React.createElement("nav", { className: "flex-column col-3 landing-inner-cop-nav" }, copData.map((cop) => {
                        const isActive = cop.id == currentDatum.id;
                        return (React.createElement(InnerCopNavCard, { key: cop.id, isActive: isActive, onClick: () => handleCopData(cop.id), addClass: "flex-container justify-center align-center p-2" },
                            React.createElement("div", { className: "pr-2" },
                                React.createElement(cop.icon, { fill: isActive ? "white" : "black", stroke: isActive ? "white" : "black", strokeWidth: "0.2", height: "24", width: "24", "aria-hidden": "true" })),
                            React.createElement("span", { className: "title-6 landing-cop-nav-title" }, cop.title)));
                    })),
                    React.createElement("div", { className: "col-9 ml-4" },
                        React.createElement(InnerCopCard, { addClass: "landing-inner-cop-card-content" },
                            React.createElement("div", null,
                                React.createElement("div", { className: "title-3 flex-container pb-4 align-bottom" },
                                    React.createElement("div", { className: "pr-4" }, currentDatum.icon && (React.createElement(currentDatum.icon, { fill: "black", stroke: "black", strokeWidth: "0.2", height: "50", width: "50", "aria-hidden": "true" }))),
                                    currentDatum.title,
                                    " CoP"),
                                currentDatum.description),
                            React.createElement("div", null,
                                React.createElement(Button, { color: "primary", size: "md", length: "long", href: "/qualifier/1" }, "Join Us")))))))));
}
export { LandingPageCop };
