var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// Eternal Imports
import React from "react";
// Internal Imports
import { combineClasses } from "../Utility/utils";
function ProtoInput(_a) {
    var { iconPosition = "left", labelHidden = false } = _a, props = __rest(_a, ["iconPosition", "labelHidden"]);
    return (React.createElement("div", { className: combineClasses(props.addClass), ref: props.passRef },
        React.createElement("label", { className: combineClasses("title-6", "mb-1", labelHidden && "sr-only"), htmlFor: props.id }, props.label),
        React.createElement("div", { className: "flex-center-y" },
            iconPosition == "left" && props.icon && (React.createElement("span", { className: `input-icon-${iconPosition}` },
                React.createElement(props.icon, null))),
            props.children,
            iconPosition == "right" && props.icon && (React.createElement("span", { className: `input-icon-${iconPosition}` },
                React.createElement(props.icon, null))))));
}
export { ProtoInput };
