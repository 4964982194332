var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// External Imports
import React from "react";
// Internal Imports
import { combineClasses, onKey } from "../Utility/utils";
function CircleCard(_a) {
    var { size = "sm" } = _a, props = __rest(_a, ["size"]);
    return (React.createElement("div", { className: combineClasses("circle-card", `circle-card-${size}`, props.onClick && "circle-card-button", props.addClass), onClick: props.onClick, onKeyDown: props.onClick && onKey(props.onClick, "Enter"), role: props.role },
        React.createElement("div", { className: `circle-card-content align-center justify-center` }, props.children)));
}
export { CircleCard };
