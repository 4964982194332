var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// External Imports
import React, { useState, useEffect } from "react";
// Internal Imports
import { combineClasses } from "components/Utility/utils";
import { IconButton } from "components/Buttons/IconButton";
import { Card } from "components/Cards/StandardCard";
import { iconX } from "assets/images/images";
function CopCard(_a) {
    var { hidden = true, size = "sm" } = _a, props = __rest(_a, ["hidden", "size"]);
    const [isHidden, setIsHidden] = useState(hidden);
    useEffect(() => {
        setIsHidden(hidden);
    }, [isHidden]);
    return (React.createElement(Card, { addClass: combineClasses(`cop-card-${size}`, props.addClass, isHidden ? "hidden" : undefined) },
        React.createElement(IconButton, { addClass: `cop-card-${size}-x`, iconUrl: iconX, label: "close", onClick: props.onClick }),
        React.createElement("div", { className: `cop-card-${size}-content` }, props.children)));
}
function InnerCopCard(props) {
    return (React.createElement(Card, { addClass: combineClasses("inner-cop-card", props.addClass) },
        React.createElement("div", { className: "inner-cop-card-content" }, props.children)));
}
function InnerCopNavCard(_a) {
    var { isActive = false } = _a, props = __rest(_a, ["isActive"]);
    const Tag = "href" in props ? "a" : "button"; // conditionally rendered tagss
    return (React.createElement(Tag, { className: combineClasses("inner-cop-nav-card", isActive ? "active" : undefined, props.addClass), onClick: props.onClick }, props.children));
}
export { CopCard, InnerCopCard, InnerCopNavCard };
