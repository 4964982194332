var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// Eternal Imports
import React, { useEffect, useState } from "react";
// Internal Imports
import { combineClasses, onKey, range } from "../Utility/utils";
import { daysOfWeek, hoursOfDay } from "./calendar_data";
import { useDragToSelectUnselect, useDragState, } from "../Utility/hooks/dragToSelectUnselect";
function Calendar(_a) {
    var { value = "0".repeat(24 * 2 * 7) } = _a, props = __rest(_a, ["value"]);
    const [data, setData] = useState(value);
    const [isMouseDown, setIsMouseDown, toSelect, setToSelect] = useDragState();
    useEffect(() => {
        props.onChange(data);
    }, [data]);
    return (React.createElement("div", { className: combineClasses("flex-container fill", props.addClass), "data-testid": "calendar-root" },
        React.createElement(CalendarHeaderColumn, { rowNames: hoursOfDay() }),
        React.createElement("div", { style: { flex: "2 1 0" } },
            React.createElement("table", { className: "calendar" },
                React.createElement("thead", null,
                    React.createElement(CalendarHeaderRow, { columnNames: daysOfWeek })),
                React.createElement("tbody", null,
                    React.createElement("tr", { "aria-hidden": true },
                        React.createElement("td", null),
                        range(1, 7).map((_, index) => {
                            return React.createElement("td", { key: index, className: "calendar-ticks-top" });
                        })),
                    range(1, 48).map((row, index) => {
                        return (React.createElement(CalendarRow, { key: index, rowNum: row }, range(1, 7).map((column, index) => {
                            return (React.createElement(CalendarCell, { key: index, cell: { row: row, col: column }, data: data, setData: setData, isMouseDown: isMouseDown, setIsMouseDown: setIsMouseDown, toSelect: toSelect, setToSelect: setToSelect }));
                        })));
                    }))))));
}
function CalendarHeaderColumn(props) {
    var _a;
    return (React.createElement("div", { className: "calendar-header-column pr-1" },
        React.createElement("div", { "aria-hidden": "true" }), (_a = props.rowNames) === null || _a === void 0 ? void 0 :
        _a.map((name, index) => {
            return (React.createElement("div", { key: index, className: "paragraph-2" }, name));
        })));
}
function CalendarHeaderRow(props) {
    return (React.createElement("tr", { className: "calendar-header-row" },
        React.createElement("th", { "aria-hidden": "true" }),
        props.columnNames.map((name, index) => {
            return (React.createElement("th", { key: index, scope: "col" }, name));
        })));
}
function CalendarRow(props) {
    return (React.createElement("tr", { className: combineClasses("calendar-row", props.rowNum % 2 == 0 ? "dashed" : "solid") },
        React.createElement("td", { className: combineClasses("calendar-ticks-left", props.rowNum % 2 == 0 ? "dashed" : "solid"), "aria-hidden": true }),
        props.children));
}
function CalendarCell({ cell, setToSelect, setIsMouseDown, isMouseDown, data, setData, toSelect, }) {
    const [next, nextdata, handleSelect] = useDragToSelectUnselect(cell, data, toSelect);
    useEffect(() => {
        setData(nextdata);
    }, [next]);
    function mouseDown(e) {
        setToSelect(!next);
        setIsMouseDown(true);
    }
    function mouseMove(e) {
        e.preventDefault();
        handleSelect(isMouseDown);
    }
    return (React.createElement("td", { tabIndex: -1, className: combineClasses("calendar-cell", cell.row % 2 == 0 ? "dashed" : "solid", next && "selected") },
        React.createElement("div", { tabIndex: 0, role: "checkbox", "aria-checked": next, "aria-label": `I am available on ${cell.row}, ${cell.col}`, onClick: () => handleSelect(!isMouseDown), onMouseUp: () => setIsMouseDown(false), onMouseMove: mouseMove, onMouseDown: mouseDown, onKeyDown: (e) => onKey(mouseDown, "Enter")(e) })));
}
export { Calendar };
