import React, { useRef, useCallback } from "react";
import { IconChevronLeft, IconChevronRight } from "assets/images/images";
import { combineClasses } from "components/Utility/utils";
function ChevronScroll(props) {
    const [showRightChevron, setShowRightChevron] = React.useState(true);
    const [showLeftChevron, setShowLeftChevron] = React.useState(false);
    const [childIndex, setChildIndex] = React.useState(0);
    const scrollRef = useRef(null);
    const handleChevronVisibility = useCallback((e) => {
        const { scrollLeft, scrollWidth, clientWidth } = e.currentTarget;
        const maxScroll = scrollWidth - clientWidth - 1;
        // show left chevron if not at start of scrollable area
        setShowLeftChevron(scrollLeft !== 0);
        //show right chevron if not at end of scrollable area
        setShowRightChevron(scrollLeft < maxScroll);
    }, []);
    const scrollMove = (direction) => {
        if (scrollRef.current) {
            const { children } = scrollRef.current;
            // Move the scroll bar left or right by the width of each child element
            if (direction === "right") {
                scrollRef.current.scrollBy({
                    left: children[childIndex].scrollWidth,
                    behavior: "smooth",
                });
                setChildIndex(childIndex + 1);
            }
            else if (direction === "left") {
                // if at end of scrollable area, don't want to skip over partially cut element
                if (!showRightChevron) {
                    scrollRef.current.scrollBy({
                        left: -1,
                        behavior: "smooth",
                    });
                }
                else {
                    scrollRef.current.scrollBy({
                        left: -children[childIndex - 1].scrollWidth,
                        behavior: "smooth",
                    });
                }
                setChildIndex(childIndex - 1);
            }
        }
    };
    return (React.createElement("div", { className: "chevron-scroll-outer-container" },
        React.createElement("button", { className: combineClasses("chevron-scroll-left-btn", "align-center", "justify-center", "row", showLeftChevron ? undefined : "hidden"), onClick: () => scrollMove("left"), "aria-label": "Scroll left" },
            React.createElement(IconChevronLeft, null)),
        React.createElement("div", { ref: scrollRef, onScroll: handleChevronVisibility, className: "chevron-scroll-child-container" }, props.children),
        React.createElement("button", { className: combineClasses("chevron-scroll-right-btn", "align-center", "row", showRightChevron ? undefined : "hidden"), onClick: () => scrollMove("right"), "aria-label": "Scroll right" },
            React.createElement(IconChevronRight, null)),
        React.createElement("button", { className: "chevron-scroll-clear-btn" }, "Clear all")));
}
export { ChevronScroll };
