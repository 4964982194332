var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// External Imports
import { combineClasses } from "components/Utility/utils";
import React, { useEffect, useState } from "react";
import { IconButton } from "components/components";
// Internal Imports
import { iconX } from "../../assets/images/images";
import { TransitionWrapper } from "components/components";
function Notification(_a) {
    var { autoHidden = false, closable = false, fade = false, role = "status", show = true } = _a, props = __rest(_a, ["autoHidden", "closable", "fade", "role", "show"]);
    const [isHidden, setIsHidden] = useState(false);
    const [isShow, setIsShow] = useState(show);
    useEffect(() => {
        setIsShow(show);
        if (!show) {
            setIsHidden(true);
        }
    }, [show]);
    const CloseButton = () => {
        function handleClick() {
            if (autoHidden || fade) {
                setIsShow(false);
            }
            else {
                setIsHidden(true);
            }
        }
        return (React.createElement(IconButton, { addClass: "notification-x", iconUrl: iconX, label: "close", onClick: () => {
                handleClick();
            } }));
    };
    const Bar = () => {
        return (React.createElement("div", { className: combineClasses("flex-center-x", "align-center", "notification", isHidden && "hidden"), "aria-hidden": isHidden, role: role },
            closable && React.createElement(CloseButton, null),
            React.createElement("div", { className: "paragraph-3 text-center" }, props.children)));
    };
    return fade || autoHidden ? (React.createElement(TransitionWrapper, { show: isShow, autoExit: autoHidden },
        React.createElement(Bar, null))) : (React.createElement(Bar, null));
}
export { Notification };
