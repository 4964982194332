import React from "react";
const DemoTailwind = () => {
    return (React.createElement("div", { className: "container p-6" },
        React.createElement("h2", null, "Tailwind Styled Components"),
        React.createElement("a", { className: "underline", href: "https://tailwindcss.com/docs/installation" }, "Docs"),
        React.createElement("br", null),
        React.createElement("a", { className: "font-medium text-blue-600 dark:text-blue-500 hover:underline", href: "https://v1.tailwindcss.com/components/buttons" }, "Buttons"),
        React.createElement("br", null),
        React.createElement("div", { className: "m-3 flex flex-wrap" },
            React.createElement("button", { className: "h-8 px-3 rounded text-base  font-bold leading-extra-tight bg-blue-dark hover:bg-blue-dark-hover hover:shadow-lg focus:bg-blue-dark-focused text-white " }, "Small"),
            React.createElement("button", { className: "h-10 px-5 rounded text-base font-bold bg-blue-dark hover:bg-blue-dark-hover hover:shadow-lg focus:bg-blue-dark-focused text-white " }, "Medium-Long"),
            React.createElement("button", { className: "h-10 px-3 rounded text-base font-bold bg-blue-dark hover:bg-blue-dark-hover hover:shadow-lg focus:bg-blue-dark-focused text-white " }, "Medium-Narrow"),
            React.createElement("button", { className: "h-10 px-4 rounded text-base font-bold bg-blue-dark hover:bg-blue-dark-hover hover:shadow-lg focus:bg-blue-dark-focused text-white " }, "Medium"),
            React.createElement("button", { className: "h-14 px-6 rounded-large text-xl font-bold bg-blue-dark hover:bg-blue-dark-hover hover:shadow-lg focus:bg-blue-dark-focused text-white " }, "Large"),
            React.createElement("button", { className: "h-16 px-6 rounded-x-large text-2xl font-bold bg-blue-dark hover:bg-blue-dark-hover hover:shadow-lg focus:bg-blue-dark-focused text-white " }, "X-Large-Long")),
        React.createElement("div", { className: "max-w-sm rounded overflow-hidden shadow-lg" },
            React.createElement("img", { className: "w-full", src: "https://images.pexels.com/photos/17021500/pexels-photo-17021500/free-photo-of-cat-looking-up.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", alt: "Sunset in the mountains" }),
            React.createElement("div", { className: "px-6 py-4" },
                React.createElement("div", { className: "font-bold text-xl mb-2" }, "Card"),
                React.createElement("p", { className: "text-gray-700 text-base" }, "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.")),
            React.createElement("div", { className: "px-6 pt-4 pb-2" },
                React.createElement("span", { className: "inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2" }, "#photography"),
                React.createElement("span", { className: "inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2" }, "#cat"),
                React.createElement("span", { className: "inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2" }, "#pet"))),
        React.createElement("img", { src: "https://cdn-icons-png.flaticon.com/128/1082/1082240.png", alt: "" }),
        React.createElement("span", null, "Testing whether img has display:block set by tailwind preflight")));
};
export default DemoTailwind;
