// External Imports
import React from "react";
// Internal Imports
import { logoHorizontal } from "assets/images/images";
import { IconHamburgerMenu } from "assets/images/images";
import { Button } from "../components/components";
import { Link } from "react-router-dom";
const menuItems = [
    { name: "Hack for LA", link: "https://www.hackforla.org/" },
    {
        name: "How to Join",
        link: "https://www.hackforla.org/getting-started",
    },
    { name: "Projects", link: "https://www.hackforla.org/projects/" },
];
function HeaderNav() {
    const Logo = () => {
        return (React.createElement("a", { href: "/", rel: "noopener noreferrer" },
            React.createElement("img", { className: "max-h-[24px] md:max-h-[32px]", src: logoHorizontal, alt: "Civic Tech Jobs - Home" })));
    };
    return (React.createElement("header", { className: "h-16 py-1 px-3 w-full flex items-center justify-between lg:justify-around shadow-[-1px_1px_2px_rgb(51,51,51,0.2)]" },
        React.createElement("div", null,
            React.createElement(Logo, null)),
        React.createElement("div", { className: "flex items-center" },
            React.createElement("nav", { className: "max-md:hidden flex items-center justify-center", "aria-label": "header-navigation" }, menuItems.map((item, index) => {
                return (React.createElement("a", { className: "hover:underline font-bold md:mx-6 lg:mx-8", href: item.link, rel: "noopener noreferrer", key: index }, item.name));
            })),
            React.createElement(Link, { to: "/login", className: "mg:ml-6 lg:ml-8" },
                React.createElement(Button, { color: "primary", size: "sm" }, "Log In")),
            React.createElement("button", { className: "md:hidden ml-3", "aria-expanded": "false", "aria-controls": "menu" },
                React.createElement(IconHamburgerMenu, null)))));
}
export default HeaderNav;
