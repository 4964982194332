import React from "react";
import { useLocation } from "react-router-dom";
import { AuthNav } from "tw-components";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import { loginTanBg, dotsSvg, loginIllustration } from "assets/images/images";
/** AuthenticationPage
 * @dev handles both "/login" and "/signup" paths
 */
export default function AuthenticationPage() {
    const { pathname } = useLocation();
    return (React.createElement(React.Fragment, null,
        React.createElement(AuthNav, null),
        React.createElement("div", { className: "flex flex-row", style: { height: "calc(100vh - 64px)", overflow: "hidden" } },
            React.createElement("div", { className: "max-lg:hidden lg:basis-1/2 bg-tan-light relative" },
                React.createElement("img", { src: loginIllustration, alt: "Team work Pana Illustration", className: "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4/5 z-20" }),
                React.createElement("img", { src: loginTanBg, alt: "Tan background for login/register page", className: "absolute inset-x-0 bottom-0 w-full" }),
                React.createElement("img", { src: dotsSvg, alt: "Corner dots pattern", className: "absolute -top-4 -right-4 w-1/6 h-1/6 transform rotate-290 z-10" }),
                React.createElement("img", { src: dotsSvg, alt: "Corner dots pattern", className: "absolute -bottom-4 -left-4 w-1/6 h-1/6 transform rotate-345 z-10" })),
            React.createElement("div", { className: "w-full lg:basis-1/2 bg-tan" },
                React.createElement("div", { className: "flex flex-col justify-center items-center h-full lg:bg-white" },
                    React.createElement("div", { className: "w-10/12 lg:w-[439px]" },
                        React.createElement("div", { className: "bg-white rounded-2xl lg:bg-transparent max-lg:p-7" },
                            pathname === "/login" && React.createElement(LoginForm, null),
                            pathname === "/signup" && React.createElement(SignupForm, null))))))));
}
