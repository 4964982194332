var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// External Imports
import React from "react";
// Internal Imports
import { combineClasses } from "../Utility/utils";
// Default button size/colour/variant is small/primary/base
function Button(_a) {
    var { size = "sm", color = "primary", length = "", // empty string is falsy
    rel = "noopener noreferrer" } = _a, props = __rest(_a, ["size", "color", "length", "rel"]);
    const Tag = "href" in props ? "a" : "button"; // conditionally rendered tags
    return (React.createElement(Tag, { className: combineClasses("btn", `btn-${size}`, `btn-${color}`, length && `btn-${size}-${length}`, props.disabled && "disabled", props.addClass), href: props.href, target: props.href ? props.target : undefined, rel: props.href ? rel : undefined, onClick: props.onClick }, props.children));
}
export { Button };
