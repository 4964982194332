// External Imports
import React, { Fragment } from "react";
import { LandingPageIntro } from "./LandingPageIntro";
import { LandingPageCop } from "./LandingPageCop";
function LandingPage() {
    return (React.createElement(Fragment, null,
        React.createElement("main", null,
            React.createElement(LandingPageIntro, null),
            React.createElement(LandingPageCop, null))));
}
export { LandingPage };
