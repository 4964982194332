// External Imports
import React, { Fragment, Suspense } from "react";
import { Outlet, useLoaderData } from "react-router-dom";
// Internal Imports
import { ProgressBar } from "components/components";
// Lazy Imports
const QualifierPageRoles = React.lazy(() => import("./QualifierPageRoles"));
const QualifierPageCalendar = React.lazy(() => import("./QualifierPageCalendar"));
function loader({ params }) {
    return params.page;
}
function Content({ page }) {
    switch (page) {
        case "1":
            return React.createElement(QualifierPageRoles, null);
        case "2":
            return React.createElement(QualifierPageCalendar, null);
        default:
            return React.createElement("div", null, "404 page...");
    }
}
function QualifierContent() {
    const page = useLoaderData();
    return (React.createElement(Fragment, null,
        React.createElement(ProgressBar, { label: `Page ${page}`, value: parseInt(page), addClass: "px-5" }),
        React.createElement("div", { className: "flex-center-x" },
            React.createElement("div", { className: "flex-column qualifier-content align-center px-5" },
                React.createElement(Content, { page: page }))),
        " "));
}
function QualifierPage() {
    return (React.createElement(Fragment, null,
        React.createElement(Suspense, { fallback: React.createElement("div", null, "...Loading") },
            React.createElement("main", { className: "mx-6" },
                React.createElement(Outlet, null)))));
}
export { QualifierPage, QualifierContent, loader };
