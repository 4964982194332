var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// External exports
import React, { useState, useRef, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
const TransitionWrapper = (_a) => {
    var { autoExit = false, show = true } = _a, props = __rest(_a, ["autoExit", "show"]);
    const [isNotificationOnDOM, setIsNotificationOnDOM] = useState(show);
    const nodeRef = useRef(null);
    const autoHiddenTimeout = 500;
    useEffect(() => {
        setIsNotificationOnDOM(show);
    }, [show]);
    return (React.createElement(CSSTransition, { in: isNotificationOnDOM, nodeRef: nodeRef, classNames: "fade", timeout: 1000, unmountOnExit: true, appear: true, onEntered: () => {
            if (autoExit) {
                window.setTimeout(() => {
                    setIsNotificationOnDOM(false);
                }, autoHiddenTimeout);
            }
        }, onExited: () => {
            if (props.onExited) {
                props.onExited();
            }
        } },
        React.createElement("div", { ref: nodeRef }, props.children)));
};
export { TransitionWrapper };
