// Eternal Imports
import { iconCheckMark, iconPlus } from "assets/images/images";
import React, { useEffect, useState } from "react";
// Internal Imports
import { combineClasses } from "../Utility/utils";
function Chip({ addClass, checked = false, onClick, value, variant = "single", }) {
    const [active, isActive] = useState(checked);
    useEffect(() => {
        isActive(checked);
    }, [checked]);
    function handleClick() {
        if (onClick)
            onClick(!active, value);
        isActive(!active);
    }
    function MultiSelectIcon() {
        if (active) {
            return (React.createElement("img", { src: iconCheckMark, alt: "Checkmark", className: "pr-1", "aria-hidden": "true" }));
        }
        else {
            return (React.createElement("img", { src: iconPlus, alt: "Plus sign", className: "pr-1", "aria-hidden": "true" }));
        }
    }
    return (React.createElement("button", { onClick: handleClick, className: combineClasses(`${variant}-chip`, "px-4", "paragraph-3", active && "active", addClass), role: "checkbox", "aria-checked": active },
        variant == "multi" && React.createElement(MultiSelectIcon, null),
        value));
}
export { Chip };
