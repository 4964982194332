var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// External Imports
import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
// Internal Imports
import { combineClasses } from "../Utility/utils";
function Dialog(_a) {
    var { open = false } = _a, props = __rest(_a, ["open"]);
    const [isBackdropOpen, setIsBackdropOpen] = useState(false);
    const windowRef = useRef(null);
    const nodeRef = useRef(null);
    // Adjust padding on body when scrollbar is hidden so that page content does not jump
    useEffect(() => {
        if (isBackdropOpen) {
            const scrollWidth = Math.abs(window.innerWidth - document.documentElement.clientWidth);
            document.body.style.paddingRight = `${scrollWidth}px`;
            document.body.style.overflow = "hidden";
        }
        else {
            document.body.style.removeProperty("padding-right");
            document.body.style.overflow = "auto";
        }
    }, [isBackdropOpen]);
    useEffect(() => {
        if (open)
            setIsBackdropOpen(true);
    });
    function handleClose(e) {
        if (e.target === windowRef.current) {
            props.onClose();
        }
    }
    return (React.createElement("div", { className: combineClasses("dialog-backdrop", !isBackdropOpen && "hidden", props.addClass), ref: windowRef, onClick: handleClose, role: "presentation" },
        React.createElement(CSSTransition, { in: open, classNames: "dialog", timeout: 400, unmountOnExit: true, onEnter: () => setIsBackdropOpen(true), onExited: () => {
                setIsBackdropOpen(false);
            }, nodeRef: nodeRef },
            React.createElement("div", { className: combineClasses(props.addClass), role: "dialog", "aria-label": props.ariaLabel, tabIndex: -1, ref: nodeRef }, props.children))));
}
export { Dialog };
